import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { OperationTypeListingPageQuery } from '../../gatsby-graphql';
import PropertiesListingDiscovery from '../components/PropertiesListingDiscovery/PropertiesListingDiscovery';

interface IOperationTypeListingTemplateProps extends PageRendererProps {
  data: OperationTypeListingPageQuery;
  pageContext: {
    operationTypeSlug: string;
    operationTypeListingCountLabel: string;
  };
}

const OperationTypeListingTemplate: React.FC<IOperationTypeListingTemplateProps> = ({
  data: {
    navigation,
    footer,
    properties,
    propertyLocations,
    propertiesListingConfig,
    propertySpecs,
  },
  pageContext: { operationTypeListingCountLabel },
}) => (
  <Layout navigation={navigation} footer={footer}>
    <PropertiesListingDiscovery
      {...{
        propertyLocations,
      }}
      propertiesListingConfig={propertiesListingConfig.nodes[0]}
      propertySpecs={propertySpecs.nodes}
      properties={properties.nodes}
      totalProperties={properties.totalCount}
      propertiesCountLabel={operationTypeListingCountLabel}
    />
  </Layout>
);

export const pageQuery = graphql`
  query OperationTypeListingPage($operationTypeId: String!) {
    ...SiteNavigation
    ...PageListingFilters
    properties: allContentfulProperty(
      sort: { fields: [createdAt], order: [DESC] }
      filter: { operationType: { id: { eq: $operationTypeId } } }
    ) {
      totalCount
      nodes {
        ...PropertyCard
      }
    }
  }
`;

export default OperationTypeListingTemplate;
